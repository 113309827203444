
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  methods: {
    imageGridStyle(index) {
      switch (index) {
        case 0:
          return {
            gridPosition: 'col-span-2 col-start-2 xl:px-10 pl-10 pr-0',
            aspectRatio: 'aspect-w-6 aspect-h-4',
          }
        case 1:
          return {
            gridPosition: 'col-start-1 row-span-2 row-start-1 pt-16 xl:-ml-5 ',
            aspectRatio: 'aspect-w-4 aspect-h-6',
          }
        case 2:
          return {
            gridPosition:
              'col-span-2 col-start-2 row-start-2 xl:pl-20 xl:pr-28 pl-14 pr-8 pt-5 xl:pt-0',

            aspectRatio: 'aspect-w-6 aspect-h-4',
          }
        case 3:
          return {
            gridPosition:
              'col-span-2 row-start-3 xl:pl-9 xl:pr-28 pr-16 pt-5 xl:pt-0',

            aspectRatio: 'aspect-w-14 aspect-h-10',
          }
        case 4:
          return {
            gridPosition:
              'col-start-3 row-span-2 row-start-3 pt-10 -ml-10 xl:pr-10 xl:-mt-28 pl-5 xl:pl-0',

            aspectRatio: 'aspect-w-4 aspect-h-6',
          }
        case 5:
          return {
            gridPosition:
              'col-span-2 row-start-4 xl:-ml-24 xl:pr-28 xl:pr-20 pr-12 xl:pl-10 pl-8 xl:pl-0 pt-10 xl:mr-24',

            aspectRatio: 'aspect-w-6 aspect-h-4',
          }
        default:
          return { gridPosition: '', aspectRatio: '' }
        // code block
      }
    },
  },
}
